import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import type { BeforeAfterProps } from '..';

export const BEFORE_AFTER_BRAND_RESPONSE_CONTENT: BeforeAfterProps = {
  quote:
    'My provider is the best. I tell her what’s going on and she walks me through what exact changes she’s going to make to my formula and why. She also gives me recommendations of products I can add to my skincare routine.',
  credit: 'Margot, Real Curology patient',
  beforeLabel: 'Before Curology',
  afterLabel: 'After Curology',
  beforeImageMobile: (
    <StaticImage
      src="../images/beforeBrandResponseMobile@2x.jpg"
      alt="Margot's before image"
      height={216}
    />
  ),
  beforeImageDesktop: (
    <StaticImage
      src="../images/beforeBrandResponseDesktop@2x.jpg"
      alt="Margot's before image"
      height={280}
    />
  ),
  afterImageMobile: (
    <StaticImage
      src="../images/afterBrandResponseMobile@2x.jpg"
      alt="Margot's after image"
      height={216}
    />
  ),
  afterImageDesktop: (
    <StaticImage
      src="../images/afterBrandResponseDesktop@2x.jpg"
      alt="Margot's after image"
      height={280}
    />
  ),
};

import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import type { BeforeAfterProps } from '..';

export const BEFORE_AFTER_ROSACEA_CONTENT: BeforeAfterProps = {
  quote:
    'The Custom Formula changed my life. I obviously still have rosacea—there isn’t a cure for it so it’s never going away completely—but the appearance is so much more soothed, my makeup applies smoother, and I feel so much more confident in my skin.',
  credit: 'Mary, Real Curology patient',
  beforeLabel: 'Before Curology',
  afterLabel: 'After 7 months',
  beforeImageMobile: (
    <StaticImage
      src="../images/beforeRosaceaMobile@2x.jpg"
      alt="Mary's before image"
      height={215}
    />
  ),
  beforeImageDesktop: (
    <StaticImage
      src="../images/beforeRosaceaDesktop@2x.jpg"
      alt="Mary's before image"
      height={278}
    />
  ),
  afterImageMobile: (
    <StaticImage
      src="../images/afterRosaceaMobile@2x.jpg"
      alt="Mary's after image"
      height={215}
    />
  ),
  afterImageDesktop: (
    <StaticImage
      src="../images/afterRosaceaDesktop@2x.jpg"
      alt="Mary's after image"
      height={278}
    />
  ),
};

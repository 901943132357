import {
  FineLinesGlyph,
  SunGlyph,
  FaceGlyph,
  SuperbottleGlyph,
} from 'radiance-ui/lib/icons';

import type { BasicsProps } from '..';

export const BASICS_ROSACEA_CONTENT: BasicsProps = {
  title: 'Everything you need to know about rosacea',
  items: [
    {
      glyph: FineLinesGlyph,
      title: 'What is rosacea?',
      description:
        'A chronic skin condition that can cause recurrent, obvious skin redness (in addition to other symptoms).',
    },
    {
      glyph: SunGlyph,
      title: 'What triggers rosacea flare-ups?',
      description:
        'Some of the most common triggers are sun exposure, stress, and hot drinks or spicy foods.',
    },
    {
      glyph: FaceGlyph,
      title: 'Signs of rosacea',
      description:
        'Symptoms can include presistent redness around your nose and cheeks, frequent blushing, and visible blood vessels.',
    },
    {
      glyph: SuperbottleGlyph,
      title: 'Can rosacea be treated?',
      description:
        'While it can’t be cured, our Custom Formula can now include ingredients that help control rosacea’s symptoms.',
    },
  ],
};

import React from 'react';

import {
  OuterContainer,
  InnerContainer,
  BeforeAfterContainer,
  BeforeAfterImages,
  BeforeAfterImageContainer,
  BeforeAfterLabel,
  QuoteContainer,
  Quotation,
  QuoteCredit,
  disclaimerStyles,
  BeforeAfterImageMobile,
  BeforeAfterImageDesktop,
  quoteStyles,
} from './BeforeAfter.style';

export interface BeforeAfterProps {
  quote: string;
  credit: string;
  beforeLabel: string;
  afterLabel: string;
  beforeImageMobile: React.ReactNode;
  beforeImageDesktop: React.ReactNode;
  afterImageMobile: React.ReactNode;
  afterImageDesktop: React.ReactNode;
}

const moduleName = 'beforeAfter';

export const BeforeAfter = ({
  quote,
  credit,
  beforeLabel,
  afterLabel,
  beforeImageMobile,
  beforeImageDesktop,
  afterImageMobile,
  afterImageDesktop,
}: BeforeAfterProps) => (
  <OuterContainer data-module={moduleName}>
    <InnerContainer>
      <BeforeAfterContainer>
        <small css={disclaimerStyles}>Results may vary.</small>
        <BeforeAfterImages>
          <BeforeAfterImageContainer>
            <BeforeAfterImageMobile>{beforeImageMobile}</BeforeAfterImageMobile>
            <BeforeAfterImageDesktop>
              {beforeImageDesktop}
            </BeforeAfterImageDesktop>
            <BeforeAfterLabel>{beforeLabel}</BeforeAfterLabel>
          </BeforeAfterImageContainer>
          <BeforeAfterImageContainer>
            <BeforeAfterImageMobile>{afterImageMobile}</BeforeAfterImageMobile>
            <BeforeAfterImageDesktop>
              {afterImageDesktop}
            </BeforeAfterImageDesktop>
            <BeforeAfterLabel>{afterLabel}</BeforeAfterLabel>
          </BeforeAfterImageContainer>
        </BeforeAfterImages>
      </BeforeAfterContainer>
      <QuoteContainer>
        <Quotation>“</Quotation>
        <h3 css={quoteStyles}>{quote}</h3>
        <QuoteCredit>{credit}</QuoteCredit>
      </QuoteContainer>
    </InnerContainer>
  </OuterContainer>
);

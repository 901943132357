import React from 'react';

import IngredientsTretinoinPage from 'components/pages/free-trial/tretinoin';
import { LandingPageWrapper } from 'components/global/LandingPageWrapper';

const IngredientsTretinoinLanding = () => (
  <LandingPageWrapper>
    <IngredientsTretinoinPage />
  </LandingPageWrapper>
);

export default IngredientsTretinoinLanding;

import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

export const AZELAIC = (
  <StaticImage
    id="skinGoals_imgAzelaic"
    key="azelaic"
    src="../images/ingredients/azelaic@2x.png"
    alt="azelaic"
    width={80}
  />
);

export const NIACINAMIDE = (
  <StaticImage
    id="skinGoals_imgNiacinamide"
    key="niacinamide"
    src="../images/ingredients/niacinamide@2x.png"
    alt="niacinamide"
    width={80}
  />
);

export const CLINDAMYCIN = (
  <StaticImage
    id="skinGoals_imgClindamycin"
    key="clindamycin"
    src="../images/ingredients/clindamycin@2x.png"
    alt="clindamycin"
    width={80}
  />
);

export const TRETINOIN = (
  <StaticImage
    id="skinGoals_imgTretinoin"
    key="tretinoin"
    src="../images/ingredients/tretinoin@2x.png"
    alt="tretinoin"
    width={80}
  />
);

export const ZINC = (
  <StaticImage
    id="skinGoals_imgZinc"
    key="zinc"
    src="../images/ingredients/zinc@2x.png"
    alt="zinc"
    width={80}
  />
);

export const METRONIDAZOLE = (
  <StaticImage
    id="skinGoals_imgMetronidazole"
    key="metronidazole"
    src="../images/ingredients/metronidazole@2x.png"
    alt="metronidazole"
    width={80}
  />
);

export const IVERMECTIN = (
  <StaticImage
    id="skinGoals_imgIvermectin"
    key="ivermectin"
    src="../images/ingredients/ivermectin@2x.png"
    alt="ivermectin"
    width={78}
  />
);

import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { TYPOGRAPHY_STYLE } from 'radiance-ui';
import type { ThemeType } from 'radiance-ui/lib/constants';
import { MEDIA_QUERIES, SPACER } from 'radiance-ui/lib/constants';

import { LOCAL_CONTENT_MAX_WIDTH } from 'styles/constants';

import backgroundDesktop from './images/backgroundDesktop@2x.jpg';
import backgroundMobile from './images/backgroundMobile@2x.jpg';
import type { BackgroundOverride } from '.';

export const OuterContainer = styled.div<{
  backgroundOverride?: BackgroundOverride;
}>`
  background-image: ${({ backgroundOverride }) =>
    backgroundOverride
      ? `url(${backgroundOverride.mobile})`
      : `url(${backgroundMobile})`};
  background-size: cover;
  padding: ${SPACER.x4large} ${SPACER.large};

  ${MEDIA_QUERIES.mdUp} {
    background-image: ${({ backgroundOverride }) =>
      backgroundOverride
        ? `url(${backgroundOverride.desktop})`
        : `url(${backgroundDesktop})`};
    padding: 5.5rem 0;
  }
`;

export const InnerContainer = styled.div`
  max-width: ${LOCAL_CONTENT_MAX_WIDTH};
  margin: 0 auto;
`;

const tableBorder = (theme: ThemeType) => `solid 1px ${theme.COLORS.primary}`;

export const Table = styled.div`
  max-width: 776px;
  border-left: ${({ theme }) => tableBorder(theme)};
  border-top: ${({ theme }) => tableBorder(theme)};
  background-color: ${({ theme }) => theme.COLORS.white};
  margin: 0 auto;
`;

export const TableHeader = styled.div`
  max-width: ${LOCAL_CONTENT_MAX_WIDTH};
  padding: ${SPACER.large};
  border-right: ${({ theme }) => tableBorder(theme)};
  border-bottom: ${({ theme }) => tableBorder(theme)};
  text-align: left;

  ${MEDIA_QUERIES.mdUp} {
    text-align: center;
    padding: ${SPACER.medium} 0;
  }
`;

export const TableContent = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const TableItem = styled.div`
  padding: ${SPACER.large};
  width: 100%;
  border-bottom: ${({ theme }) => tableBorder(theme)};
  border-right: ${({ theme }) => tableBorder(theme)};

  ${MEDIA_QUERIES.mdUp} {
    padding: ${SPACER.xlarge};
    width: 50%;
  }
`;

export const TableItemTitle = styled.div`
  display: flex;
  margin-bottom: ${SPACER.small};
  align-items: center;
`;

export const glyphStyles = css`
  margin-right: ${SPACER.small};
  margin-left: -${SPACER.small};
`;

export const itemTitleStyles = (theme: ThemeType) => css`
  ${TYPOGRAPHY_STYLE.body(theme)};
  color: ${theme.COLORS.primary};
`;

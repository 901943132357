import { NavBannerProps } from 'components/global/Nav/components';
import { COOKIE_KEYS } from 'consts/cookieKeys';
import { TRY_CUROLOGY_30_DAYS } from 'consts/copy';

export default {
  isClosable: true,
  aboveNavigation: true,
  ctaText: TRY_CUROLOGY_30_DAYS,
  cookieKey: COOKIE_KEYS.hideTretinoinBanner,
} satisfies NavBannerProps;

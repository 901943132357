import {
  FaceGlyph,
  FineLinesGlyph,
  NiacinamideGlyph,
  TimeAlwaysGlyph,
} from 'radiance-ui/lib/icons';

import type { BasicsProps } from '..';

export const BASICS_NIACINAMIDE_CONTENT: BasicsProps = {
  title: 'What to know about niacinamide',
  items: [
    {
      glyph: NiacinamideGlyph,
      title: 'What it is',
      description:
        'Niacinamide is a form of vitamin B3 with antioxidant and anti-inflammatory properties.',
    },
    {
      glyph: FineLinesGlyph,
      title: 'How it works',
      description:
        'Two words: barrier repair. It helps strengthen skin’s barrier function and fend off damage.',
    },
    {
      glyph: FaceGlyph,
      title: 'Who it’s for',
      description:
        'This gentle multitasker can benefit all skin types and help with many skin concerns—from dark spots to fine lines.',
    },
    {
      glyph: TimeAlwaysGlyph,
      title: 'Where it fits in a routine',
      description:
        'Apply it nightly in your Custom Formula between cleansing and moisturizing—it works while you sleep.',
    },
  ],
};

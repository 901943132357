import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { primaryTheme } from 'radiance-ui/lib/constants';

import type { SkinGoalsProps } from '..';
import { AZELAIC, NIACINAMIDE, TRETINOIN } from './ingredientsInfo';

export const SKIN_GOALS_NIACINAMIDE_CONTENT: SkinGoalsProps = {
  header: 'Niacinamide shines in personalized skincare',
  description:
    'If it’s right for your skin, a real dermatology provider creates your Custom Formula with niacinamide plus two more research-backed active ingredients—like tretinoin, azelaic acid, and more! We’ll check in to see how you’re doing, and your provider can adjust the strength or ingredients as needed.',
  phoneDesktop: (
    <StaticImage
      id="skinGoals_imgPhoneDesktop"
      src="../images/phoneDesktopNiacinamide@2x.jpg"
      alt="phone logged into Curology app"
      width={371}
    />
  ),
  phoneMobile: (
    <StaticImage
      id="skinGoals_imgPhoneMobile"
      src="../images/phoneMobileNiacinamide@2x.jpg"
      alt="phone logged into Curology app"
      layout="fullWidth"
    />
  ),
  ingredients: [NIACINAMIDE, TRETINOIN, AZELAIC],
  ctaButtonText: 'Discover your formula',
  ctaDisclaimerText:
    'Ingredients subject to provider consultation.\nSubject to consultation.',
  backgroundColor: primaryTheme.COLORS.white,
  showPriceBadge: true,
};

import {
  CloggedPoresGlyph,
  FaceGlyph,
  TimeAlwaysGlyph,
  TretinoinGlyph,
} from 'radiance-ui/lib/icons';

import type { BasicsProps } from '..';

export const BASICS_TRETINOIN_CONTENT: BasicsProps = {
  title: 'What to know about tretinoin',
  items: [
    {
      glyph: TretinoinGlyph,
      title: 'What it is',
      description:
        'Tretinoin is a topical, prescription-only vitamin A derivative (like retinol but stronger).',
    },
    {
      glyph: CloggedPoresGlyph,
      title: 'How it works',
      description:
        'It helps more quickly shed dull, dead, pore-clogging skin cells by regulating cell turnover.',
    },
    {
      glyph: FaceGlyph,
      title: 'Who it’s for',
      description:
        'This major multitasker is the gold standard for both acne and anti-aging, so it’s useful for many people.',
    },
    {
      glyph: TimeAlwaysGlyph,
      title: 'Where it fits in a routine',
      description:
        'Applied nightly between cleansing and moisturizing, this powerhouse ingredient gets to work while you sleep.',
    },
  ],
};

import React from 'react';

import {
  SimpleHero,
  SIMPLE_HERO_TRETINOIN_CONTENT,
} from 'components/modules/SimpleHero';
import { SEO, SEO_TRETINOIN_CONTENT } from 'components/global/SEO';
import { Nav } from 'components/global/Nav';
import {
  SkinGoals,
  SKIN_GOALS_TRETINOIN_CONTENT,
} from 'components/modules/SkinGoals';
import { Basics, BASICS_TRETINOIN_CONTENT } from 'components/modules/Basics';
import {
  BeforeAfter,
  BEFORE_AFTER_TRETINOIN_CONTENT,
} from 'components/modules/BeforeAfter';
import {
  ArticleCarousel,
  ARTICLE_CAROUSEL_TRETINOIN_CONTENT,
} from 'components/modules/ArticleCarousel';
import { Footer } from 'components/global/Footer';
import { Pricing, PRICING_TRETINOIN_CONTENT } from 'components/modules/Pricing';
import content from './content';

const IngredientsTretinoinPage = ({
  freeTrial = false,
  hideArticleCarousel = false,
}) => {
  return (
    <React.Fragment>
      <SEO {...SEO_TRETINOIN_CONTENT} />
      <Nav freeTrial={freeTrial} bannerProps={[content]} />
      <SimpleHero {...SIMPLE_HERO_TRETINOIN_CONTENT} />
      <SkinGoals {...SKIN_GOALS_TRETINOIN_CONTENT} />
      <Basics {...BASICS_TRETINOIN_CONTENT} />
      <Pricing {...PRICING_TRETINOIN_CONTENT} />
      <BeforeAfter {...BEFORE_AFTER_TRETINOIN_CONTENT} />
      {!hideArticleCarousel && (
        <ArticleCarousel content={ARTICLE_CAROUSEL_TRETINOIN_CONTENT} />
      )}
      <Footer />
    </React.Fragment>
  );
};

export default IngredientsTretinoinPage;

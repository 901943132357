import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import { CtaButton } from 'components/global/CtaButton';

import {
  MainBox,
  InnerBox,
  headerStyles,
  IngredientsBox,
  PhoneImageMobile,
  PhoneImageDesktop,
  bottleStyles,
  MainTextBox,
  buttonMarginStyles,
  BottlePriceBadgeContainer,
  CustomBottleContainer,
  PhonePriceBadgeContainer,
} from './SkinGoals.style';
import { ReactComponent as PriceBadgeSVG } from './components/PriceBadge.svg';

export type SkinGoalsProps = {
  header: React.ReactNode;
  description: string;
  phoneDesktop: React.ReactNode;
  phoneMobile: React.ReactNode;
  ctaButtonText: string;
  ctaDisclaimerText: string;
  ingredients?: [React.ReactNode, React.ReactNode, React.ReactNode];
  backgroundColor?: string;
  showPriceBadge?: boolean;
};

const moduleName = 'skinGoals';

export const SkinGoals = ({
  header,
  description,
  phoneDesktop,
  phoneMobile,
  ctaButtonText,
  ctaDisclaimerText,
  ingredients,
  backgroundColor,
  showPriceBadge,
}: SkinGoalsProps) => (
  <MainBox data-module={moduleName} backgroundColor={backgroundColor}>
    <InnerBox>
      <CustomBottleContainer>
        <StaticImage
          src="./images/bottle@2x.png"
          alt="Curology bottle"
          css={bottleStyles}
          width={569}
        />
        {showPriceBadge && (
          <BottlePriceBadgeContainer>
            <PriceBadgeSVG />
          </BottlePriceBadgeContainer>
        )}
      </CustomBottleContainer>
      <PhoneImageDesktop>{phoneDesktop}</PhoneImageDesktop>
      <MainTextBox>
        <h2 css={headerStyles}>{header}</h2>
        <p>{description}</p>
        {ingredients && (
          <IngredientsBox>
            {ingredients[0]}+{ingredients[1]}+{ingredients[2]}
          </IngredientsBox>
        )}
        <CtaButton
          buttonText={ctaButtonText}
          disclaimerText={ctaDisclaimerText}
          module={moduleName}
          css={buttonMarginStyles}
        />
      </MainTextBox>
    </InnerBox>
    <PhoneImageMobile>
      {phoneMobile}
      {showPriceBadge && (
        <PhonePriceBadgeContainer>
          <PriceBadgeSVG />
        </PhonePriceBadgeContainer>
      )}
    </PhoneImageMobile>
  </MainBox>
);

import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import {
  CTA_UNLOCK_YOUR_OFFER,
  DISCLAIMER_CONSULTATION_CANCEL,
} from 'consts/copy';

import type { SkinGoalsProps } from '..';

export const SKIN_GOALS_CORE_CONTENT: SkinGoalsProps = {
  header: 'Meet your personal dermatology provider',
  description:
    'They’ll create your custom prescription with a powerful combination punch and provide expert guidance when you need it.',
  phoneDesktop: (
    <StaticImage
      id="skinGoals_imgPhoneDesktop"
      src="../images/phoneDesktopCore@2x.png"
      alt="phone logged into Curology app"
      width={371}
    />
  ),
  phoneMobile: (
    <StaticImage
      id="skinGoals_imgPhoneMobile"
      src="../images/phoneMobileCore@2x.png"
      alt="phone logged into Curology app"
      layout="fullWidth"
    />
  ),
  ctaButtonText: CTA_UNLOCK_YOUR_OFFER,
  ctaDisclaimerText: DISCLAIMER_CONSULTATION_CANCEL,
};

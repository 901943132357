import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { TYPOGRAPHY_STYLE } from 'radiance-ui';
import { MEDIA_QUERIES, SPACER } from 'radiance-ui/lib/constants';

import { LOCAL_CONTENT_MAX_WIDTH } from 'styles/constants';
import { desktopOnly, mobileOnly } from 'styles/mixins/displayMobileDesktop';

export const OuterContainer = styled.div`
  background-color: ${({ theme }) => theme.COLORS.background};
`;
export const InnerContainer = styled.div`
  max-width: ${LOCAL_CONTENT_MAX_WIDTH};
  margin: 0 auto;
  display: flex;
  flex-direction: column-reverse;
  padding: ${SPACER.x4large} ${SPACER.large};

  ${MEDIA_QUERIES.mdUp} {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 4.5rem 4.5rem 5.5rem;
  }
`;

export const BeforeAfterContainer = styled.div`
  max-width: ${LOCAL_CONTENT_MAX_WIDTH};
  flex: 1;
`;

export const BeforeAfterImages = styled.div`
  display: flex;
`;

export const disclaimerStyles = css`
  margin-bottom: ${SPACER.base};
  display: block;
`;

export const BeforeAfterImageContainer = styled.div`
  :first-of-type {
    margin-right: ${SPACER.medium};
  }

  ${MEDIA_QUERIES.mdUp} {
    :first-of-type {
      margin-right: ${SPACER.xlarge};
    }
  }
`;

export const BeforeAfterImageMobile = styled.div`
  margin-bottom: ${SPACER.large};
  ${mobileOnly()}
`;

export const BeforeAfterImageDesktop = styled.div`
  margin-bottom: ${SPACER.large};
  ${desktopOnly()}
`;

export const BeforeAfterLabel = styled.div`
  ${({ theme }) => TYPOGRAPHY_STYLE.button(theme)};
  color: ${({ theme }) => theme.COLORS.primary};
  text-align: center;
`;

export const QuoteContainer = styled.div`
  margin-bottom: ${SPACER.x2large};
  flex: 1;

  ${MEDIA_QUERIES.mdUp} {
    margin-bottom: 0;
    margin-left: ${SPACER.x3large};
    max-width: 496px;
  }
`;

export const Quotation = styled.div`
  font-size: 75px;
  line-height: 60px;
  font-weight: 500;
  color: ${({ theme }) => theme.COLORS.primary};
  margin-bottom: -${SPACER.medium};

  ${MEDIA_QUERIES.mdUp} {
    font-size: 88px;
  }
`;

export const quoteStyles = css`
  margin-bottom: ${SPACER.xlarge};
`;

export const QuoteCredit = styled.div`
  ${({ theme }) => TYPOGRAPHY_STYLE.button(theme)};
  color: ${({ theme }) => theme.COLORS.primary};
`;

import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { primaryTheme } from 'radiance-ui/lib/constants';

import type { SkinGoalsProps } from '..';
import { AZELAIC, METRONIDAZOLE, ZINC } from './ingredientsInfo';

export const SKIN_GOALS_METRONIDAZOLE_CONTENT: SkinGoalsProps = {
  header: 'Metronidazole meets personalized skincare',
  description:
    'If it’s right for your skin, a real dermatology provider creates your Custom Formula with metronidazole plus two more research-backed active ingredients—like azelaic acid, zinc pyrithione, and more! We’ll check in to see how you’re doing, and your provider can adjust the strength or ingredients as needed.',
  phoneDesktop: (
    <StaticImage
      id="skinGoals_imgPhoneDesktop"
      src="../images/phoneDesktopMetronidazole@2x.jpg"
      alt="phone logged into Curology app"
      width={371}
    />
  ),
  phoneMobile: (
    <StaticImage
      id="skinGoals_imgPhoneMobile"
      src="../images/phoneMobileMetronidazole@2x.jpg"
      alt="phone logged into Curology app"
      layout="fullWidth"
    />
  ),
  ingredients: [METRONIDAZOLE, AZELAIC, ZINC],
  ctaButtonText: 'Discover your formula',
  ctaDisclaimerText: 'Ingredients subject to provider consultation.',
  backgroundColor: primaryTheme.COLORS.white,
  showPriceBadge: true,
};

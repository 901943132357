import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { primaryTheme } from 'radiance-ui/lib/constants';

import {
  CTA_UNLOCK_YOUR_OFFER,
  DISCLAIMER_CONSULTATION_CANCEL,
} from 'consts/copy';

import type { SkinGoalsProps } from '..';
import { AZELAIC, NIACINAMIDE, ZINC } from './ingredientsInfo';

export const SKIN_GOALS_NEUTRAL_CONTENT: SkinGoalsProps = {
  header: 'You tell us your skin goals, we mix your prescription',
  description:
    'Acne, blackheads, clogged pores. Just tell us where you want your skin to be—and your online dermatology provider will mix powerful ingredients into a custom prescription mix.',
  phoneDesktop: (
    <StaticImage
      id="skinGoals_imgPhoneDesktop"
      src="../images/phoneDesktop@2x.png"
      alt="phone logged into Curology app"
      width={371}
    />
  ),
  phoneMobile: (
    <StaticImage
      id="skinGoals_imgPhoneMobile"
      src="../images/phoneMobile@2x.png"
      alt="phone logged into Curology app"
      layout="fullWidth"
    />
  ),
  ingredients: [ZINC, AZELAIC, NIACINAMIDE],
  backgroundColor: primaryTheme.COLORS.white,
  ctaButtonText: CTA_UNLOCK_YOUR_OFFER,
  ctaDisclaimerText: DISCLAIMER_CONSULTATION_CANCEL,
};

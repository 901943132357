import styled from '@emotion/styled';
import { css } from '@emotion/react';
import {
  MEDIA_QUERIES,
  SPACER,
  Z_SCALE,
  type ThemeType,
} from 'radiance-ui/lib/constants';
import { TYPOGRAPHY_STYLE } from 'radiance-ui';

import { LOCAL_CONTENT_MAX_WIDTH, LOCAL_COLORS } from 'styles/constants';
import { mobileOnly, desktopOnly } from 'styles/mixins/displayMobileDesktop';

export const MainBox = styled.div<{ backgroundColor?: string }>`
  background: ${({ backgroundColor }) =>
    backgroundColor || LOCAL_COLORS.skinGoalsBackground};
`;

export const InnerBox = styled.div`
  margin: auto;
  position: relative;
  padding: ${SPACER.x4large} ${SPACER.large} 0;
  max-width: ${LOCAL_CONTENT_MAX_WIDTH};

  ${MEDIA_QUERIES.mdUp} {
    padding: ${SPACER.x5large} 0;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
  }

  ${MEDIA_QUERIES.xlUp} {
    justify-content: space-between;
  }
`;

export const MainTextBox = styled.div`
  ${MEDIA_QUERIES.mdUp} {
    max-width: 368px;
    margin-left: ${SPACER.small};
    padding-right: ${SPACER.medium};
  }

  ${MEDIA_QUERIES.lgUp} {
    padding-right: 0px;
    margin-left: ${SPACER.x4large};
  }

  ${MEDIA_QUERIES.xlUp} {
    margin-left: 0;
    margin-right: 70px;
  }
`;

export const headerStyles = (theme: ThemeType) => css`
  color: ${theme.COLORS.primary};
  margin-bottom: ${SPACER.medium};
  margin-right: -${SPACER.xsmall};

  ${MEDIA_QUERIES.mdUp} {
    margin-bottom: ${SPACER.large};
  }
`;

export const IngredientsBox = styled.div`
  ${({ theme }) => TYPOGRAPHY_STYLE.title(theme)};
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-around;
  align-items: center;
  margin-top: ${SPACER.large};
  padding-bottom: ${SPACER.medium};
  color: ${({ theme }) => theme.COLORS.primary};
  font-weight: ${({ theme }) => theme.TYPOGRAPHY.fontWeight.bold};

  ${MEDIA_QUERIES.mdUp} {
    justify-content: space-between;
    max-width: 270px;
    padding-bottom: 0;
    margin-top: ${SPACER.x2large};
  }
`;

export const PhoneImageMobile = styled.div`
  ${mobileOnly()};
  width: 100%;
  margin: ${SPACER.large} auto 0;
  position: relative;
`;

export const PhoneImageDesktop = styled.div`
  ${desktopOnly()};
  width: 372px;
  z-index: ${Z_SCALE.e11};

  ${MEDIA_QUERIES.xlUp} {
    margin-left: -140px;
  }
`;

export const bottleStyles = css`
  display: none;

  ${MEDIA_QUERIES.xlUp} {
    display: block;
    width: 569px;
    margin-left: -180px;
  }
`;

export const buttonMarginStyles = css`
  margin-top: ${SPACER.x3large};

  ${MEDIA_QUERIES.mdUp} {
    margin-top: ${SPACER.large};
  }
`;

export const BottlePriceBadgeContainer = styled.div`
  position: absolute;
  top: ${SPACER.large};
  right: 50%;
  ${desktopOnly('block', 'xlUp')}
`;

export const PhonePriceBadgeContainer = styled.div`
  position: absolute;
  top: -7px;
  right: 19px;
  width: 37.333%;

  svg {
    width: 100%;
    height: 100%;
  }
`;

export const CustomBottleContainer = styled.div`
  position: relative;
`;

import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import type { BeforeAfterProps } from '..';

export const BEFORE_AFTER_TRETINOIN_CONTENT: BeforeAfterProps = {
  quote:
    'I started having stubborn and painful acne three years ago, along with a major increase in how much oil my skin produced. I decided to try Curology and am thankful I did. I have finally seen and felt such a big change in my skin!',
  credit: 'Eleisa, Real Curology patient',
  beforeLabel: 'Before Curology',
  afterLabel: 'After 4 months',
  beforeImageMobile: (
    <StaticImage
      src="../images/beforeAcneMobile@2x.jpg"
      alt="Eleisa's before image"
      height={216}
    />
  ),
  beforeImageDesktop: (
    <StaticImage
      src="../images/beforeAcne@2x.jpg"
      alt="Eleisa's before image"
      height={280}
    />
  ),
  afterImageMobile: (
    <StaticImage
      src="../images/afterAcneMobile@2x.jpg"
      alt="Eleisa's after image"
      height={216}
    />
  ),
  afterImageDesktop: (
    <StaticImage
      src="../images/afterAcne@2x.jpg"
      alt="Eleisa's after image"
      height={280}
    />
  ),
};

import {
  CloggedPoresGlyph,
  FaceGlyph,
  MetronidazoleGlyph,
  TimeAlwaysGlyph,
} from 'radiance-ui/lib/icons';

import type { BasicsProps } from '..';

export const BASICS_METRONIDAZOLE_CONTENT: BasicsProps = {
  title: 'What to know about metronidazole',
  items: [
    {
      glyph: MetronidazoleGlyph,
      title: 'What it is',
      description:
        'Metronidazole is a topical, prescription-only antibiotic with anti-inflammatory properties. ',
    },
    {
      glyph: CloggedPoresGlyph,
      title: 'How it works',
      description:
        'It calms inflammation, reduces redness, and decreases acne-like lesions from rosacea.',
    },
    {
      glyph: FaceGlyph,
      title: 'Who it’s for',
      description:
        'This skin-soother can help people with rosacea and periorificial dermatitis (POD).',
    },
    {
      glyph: TimeAlwaysGlyph,
      title: 'Where it fits in a routine',
      description:
        'Applied each night between cleansing and moisturizing, this calming ingredient works while you sleep.',
    },
  ],
};

import React from 'react';
import type { IconProps } from 'radiance-ui';

import {
  OuterContainer,
  InnerContainer,
  Table,
  TableHeader,
  TableContent,
  TableItem,
  TableItemTitle,
  glyphStyles,
  itemTitleStyles,
} from './Basics.style';

export interface BackgroundOverride {
  mobile: string;
  desktop: string;
}

export interface BasicsProps {
  title: string;
  items: Array<{
    glyph: (props: IconProps) => JSX.Element | null;
    title: string;
    description: string;
  }>;
  backgroundOverride?: BackgroundOverride;
}

export const Basics = ({ title, items, backgroundOverride }: BasicsProps) => {
  const moduleName = 'basics';

  return (
    <OuterContainer
      backgroundOverride={backgroundOverride}
      data-module={moduleName}
    >
      <InnerContainer>
        <Table>
          <TableHeader>
            <h2>{title}</h2>
          </TableHeader>
          <TableContent>
            {items.map((item) => (
              <TableItem key={item.title}>
                <TableItemTitle>
                  <item.glyph height={40} width={40} css={glyphStyles} />
                  <h3 css={itemTitleStyles}>{item.title}</h3>
                </TableItemTitle>
                <p>{item.description}</p>
              </TableItem>
            ))}
          </TableContent>
        </Table>
      </InnerContainer>
    </OuterContainer>
  );
};

import {
  ClindamycinGlyph,
  CloggedPoresGlyph,
  FaceGlyph,
  TimeAlwaysGlyph,
} from 'radiance-ui/lib/icons';

import type { BasicsProps } from '..';

export const BASICS_CLINDAMYCIN_CONTENT: BasicsProps = {
  title: 'What to know about clindamycin',
  items: [
    {
      glyph: ClindamycinGlyph,
      title: 'What it is',
      description:
        'Clindamycin is a topical, prescription antibiotic that’s relied on for treating acne.',
    },
    {
      glyph: CloggedPoresGlyph,
      title: 'How it works',
      description:
        'It fights acne-contributing bacteria and calms inflammation, helping to reduce breakouts.',
    },
    {
      glyph: FaceGlyph,
      title: 'Who it’s for',
      description:
        'This antibacterial ingredient is helpful for people with active acne—to help get it under control.',
    },
    {
      glyph: TimeAlwaysGlyph,
      title: 'Where it fits in a routine',
      description:
        'Applied nightly between cleansing and moisturizing, a Custom Formula with this antibiotic works while you sleep.',
    },
  ],
};

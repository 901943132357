import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { primaryTheme } from 'radiance-ui/lib/constants';

import type { SkinGoalsProps } from '..';

export const SKIN_GOALS_ROSACEA_MARKETING_SAFE_CONTENT: SkinGoalsProps = {
  header: (
    <React.Fragment>
      Rosacea-fighting ingredients are
      <br /> finally here
    </React.Fragment>
  ),
  description:
    'Take the guesswork out of treating your rosacea by connecting with one of our providers, who can design a Custom Formula personalized to your skin’s unique needs.',
  phoneDesktop: (
    <StaticImage
      id="skinGoals_imgPhoneDesktop"
      src="../images/phoneDesktopRosaceaMarketingSafe@2x.jpg"
      alt="phone logged into Curology app"
      width={371}
    />
  ),
  phoneMobile: (
    <StaticImage
      id="skinGoals_imgPhoneMobile"
      src="../images/phoneMobileRosaceaMarketingSafe@2x.jpg"
      alt="phone logged into Curology app"
      layout="fullWidth"
    />
  ),
  ctaButtonText: 'Discover your formula',
  ctaDisclaimerText: 'Ingredients subject to provider consultation.',
  backgroundColor: primaryTheme.COLORS.white,
  showPriceBadge: true,
};

import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { primaryTheme } from 'radiance-ui/lib/constants';

import type { SkinGoalsProps } from '..';

export const SKIN_GOALS_HYDROCORTISONE_CONTENT: SkinGoalsProps = {
  header: 'Hydrocortisone helps your skin adjust',
  description:
    'A real dermatology provider creates your Custom Formula, combining research-backed active ingredients with hydrocortisone to minimize irritation. We’ll check in to see how you’re doing, and your provider can adjust your formula as needed.',
  phoneDesktop: (
    <StaticImage
      id="skinGoals_imgPhoneDesktop"
      src="../images/phoneDesktopHydrocortisone@2x.jpg"
      alt="phone logged into Curology app"
      width={371}
    />
  ),
  phoneMobile: (
    <StaticImage
      id="skinGoals_imgPhoneMobile"
      src="../images/phoneMobileHydrocortisone@2x.jpg"
      alt="phone logged into Curology app"
      layout="fullWidth"
    />
  ),
  ctaButtonText: 'Discover your formula',
  ctaDisclaimerText: 'Ingredients subject to provider consultation.',
  backgroundColor: primaryTheme.COLORS.white,
  showPriceBadge: true,
};

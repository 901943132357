import {
  FaceGlyph,
  FineLinesGlyph,
  HydrocortisoneGlyph,
  TimeAlwaysGlyph,
} from 'radiance-ui/lib/icons';

import type { BasicsProps } from '..';

export const BASICS_HYDROCORTISONE_CONTENT: BasicsProps = {
  title: 'What to know about hydrocortisone',
  items: [
    {
      glyph: HydrocortisoneGlyph,
      title: 'What it is',
      description:
        'Hydrocortisone is a calming, anti-inflammatory topical steroid.',
    },
    {
      glyph: FineLinesGlyph,
      title: 'How it works',
      description:
        'It calms irritation and inflammation, helping your skin adjust to other active ingredients.',
    },
    {
      glyph: FaceGlyph,
      title: 'Who it’s for',
      description:
        'The ingredient can benefit people who have active ingredients in their Custom Formula.',
    },
    {
      glyph: TimeAlwaysGlyph,
      title: 'Where it fits in a routine',
      description:
        'We recommend applying your dark spot–focused Custom Formula twice daily as the last step in your routine.',
    },
  ],
};
